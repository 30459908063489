@import "../variables.scss";

.blog-post {
  h3 {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.45);
  }
  h1 {
    // font-size: 3rem !important;
    text-transform: capitalize;
    margin-bottom: 0;
  }
  .ant-page-header-heading-title {
    text-transform: capitalize;
    font-size: 3rem !important;
  }
  .blogpost-image {
    width: 33%;
  }
  .ant-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.45) !important;
  }
}
.edit-modal {
  .ant-modal-header {
    text-transform: capitalize !important;
  }
  .ant-form-item label {
    text-transform: capitalize !important;
  }
  .basic_category {
    text-transform: capitalize !important;
  }
}
.blog-post-img-container {
  position: relative;
  text-align: center;
}
.topright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}
.rich-content-display {
  pointer-events: none;
}
