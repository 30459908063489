.ant-layout {
  //   height: 100vh;
  min-height: 100vh;
  background-color: white;
  .ant-layout-header {
    padding: 0;
    ul {
      max-width: 980px;
      margin: 0 auto;
      width: 100%;
    }
  }
  .ant-layout-content {
    padding: 20px;
    max-width: 980px;
    margin: 0 auto;
    width: 100%;
  }
  .ant-card-meta-title {
    text-transform: capitalize;
  }
  .ant-breadcrumb-link {
    text-transform: capitalize;
  }
  h3 {
    text-transform: capitalize;
  }
  .ant-card-body {
    padding: 1rem !important;
  }
}
