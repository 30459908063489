.featured-carousel {
  margin-bottom: 1rem;
  .carousel-div {
    height: 100%;

    .carousel-img {
      background-position: center center;
      background-size: cover;
      width: 100%;
      height: 50vh;
      margin: 0 auto;
      //   margin: auto;
      .carousel-txt {
        font-weight: 400;
        padding-top: 12rem;
        color: white;
        font-size: 2.25em;
        text-transform: capitalize;
        font-family: "Lato", sans-serif;
      }
    }
  }
  .ant-carousel .slick-slide {
    height: 50vh;
    overflow: hidden;
    text-align: center;
  }

  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
}

.article-card-widget {
  .article-card-widget-card {
    cursor: pointer;
  }
  h3 {
    cursor: pointer;
  }
}
