$light-blue: #2b71ae;
$light-grey: #252f35;
$dark-grey: #141b1e;
$main-white: #f3f3f3;

$tik-tunes-pink: rgb(232, 37, 97);
$dashboard-main-color: #ffffff;
$dashboard-main-selected: #002140;
// $dashboard-main-selected: #1890ff
$xs_mobile_min: 320px;
$xs_mobile_max: 480px;

$md_tablet_max: 1024px;

$lg_desktop_small_min: 1200px;
$lg_desktop_small_max: 1366px;

$xl_desktop_medium_min: 1367px;
$xl_desktop_medium_max: 1600px;

$xxl_desktop_large: 1601px;
$xxl_desktop_large_max: 1920px;

@mixin phones {
  @media (min-width: #{$xs_mobile_min}) and (max-width: #{$xs_mobile_max}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$xs_mobile_max}) and (max-width: #{$md_tablet_max}) {
    @content;
  }
}
@mixin responsive {
  @media (min-width: #{$xs_mobile_min}) and (max-width: #{$md_tablet_max }) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$lg_desktop_small_min}) {
    @content;
  }
}
@mixin desktopSmall {
  @media (min-width: #{$lg_desktop_small_min}) and (max-width: #{$lg_desktop_small_max }) {
    @content;
  }
}
@mixin desktopMedium {
  @media (min-width: #{$xl_desktop_medium_min}) and (max-width: #{$xl_desktop_medium_max }) {
    @content;
  }
}
@mixin desktopLarge {
  @media (min-width: #{$xxl_desktop_large}) {
    @content;
  }
}
