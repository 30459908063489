@import "~antd/dist/antd.css";
@import "./variables.scss";

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: scroll;
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
@include phones {
  body,
  html {
    font-size: 12px !important;
  }
}
@include tablet {
  body,
  html {
    font-size: 16px !important;
  }
}
@include desktopSmall {
  body,
  html {
    font-size: 14px !important;
  }
}
@include desktopMedium {
  body,
  html {
    font-size: 16px !important;
  }
}
@include desktopLarge {
  body,
  html {
    font-size: 16px !important;
  }
}
